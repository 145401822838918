import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConvenios } from '../redux/conveniosSlice'; // Cambia la ruta según tu estructura
import { Accordion, Spinner, Alert } from 'react-bootstrap';

const PublicConveniosPage = () => {
  const dispatch = useDispatch();
  const { convenios, loading, error } = useSelector((state) => state.convenios);

  useEffect(() => {
    dispatch(fetchConvenios());
  }, [dispatch]);

  return (
    <div className="container mt-4">
      <h1 className="text-center">Convenios para nuestros socios</h1>
      
      {loading && <Spinner animation="border" variant="primary" />}
      {error && <Alert variant="danger">{error}</Alert>}

      {!loading && !error && (
        <>
          {convenios.length > 0 ? (
            <Accordion defaultActiveKey="0">
              {convenios.map((convenio, index) => (
                <Accordion.Item eventKey={index.toString()} key={convenio.id}>
                  <Accordion.Header>{convenio.nombre}</Accordion.Header>
                  <Accordion.Body>
                    <p>{convenio.descripcion}</p>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          ) : (
            <Alert variant="info" className="mt-4">
              No hay convenios disponibles en este momento.
            </Alert>
          )}
        </>
      )}
    </div>
  );
};

export default PublicConveniosPage;
