import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Form, Alert } from "react-bootstrap";

import {
  fetchConvenios,
  createConvenio,
  editConvenio,
  deactivateConvenio,
} from "../../redux/conveniosSlice";

const ConveniosPage = () => {
  const dispatch = useDispatch();
  
  const { convenios, loading, error } = useSelector((state) => state.convenios);

  const [showModal, setShowModal] = useState(false);
  const [currentConvenio, setCurrentConvenio] = useState(null);
  const [formData, setFormData] = useState({ titulo: "", descripcion: "" });

  // Fetch convenios on component load
  useEffect(() => {
    dispatch(fetchConvenios());
  }, [dispatch]);
  console.log(convenios);

 

  // Open modal for adding/editing
  const handleShowModal = (convenio = null) => {
    setCurrentConvenio(convenio);
    setFormData(convenio ? { titulo: convenio.titulo, descripcion: convenio.descripcion } : { titulo: "", descripcion: "" });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentConvenio(null);
    setFormData({ titulo: "", descripcion: "" });
  };

  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentConvenio) {
      // Editing an existing convenio
      await dispatch(editConvenio({ id: currentConvenio.id, convenio: formData }));
    } else {
      // Creating a new convenio
      await dispatch(createConvenio(formData));
    }
    handleCloseModal();
  };

  // Handle convenio deactivation
  const handleDeactivate = async (id) => {
    if (window.confirm("¿Está seguro de desactivar este convenio?")) {
      await dispatch(deactivateConvenio(id));
    }
  };

  return (
    <div className="container my-4">
      <h1 className="mb-4 text-center" style={{ color: "#d0021b" }}>Gestión de Convenios</h1>
      
      {/* Alert for errors */}
      {error && <Alert variant="danger">{error}</Alert>}

      {/* Table of convenios */}
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>Título</th>
            <th>Descripción</th>
            <th>Activo</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5" className="text-center">Cargando convenios...</td>
            </tr>
          ) : convenios.length > 0 ? (
            convenios.map((convenio) => (
              <tr key={convenio.id}>
                <td>{convenio.id}</td>
                <td>{convenio.titulo}</td>
                <td>{convenio.descripcion}</td>
                <td>{convenio.activo ? "Sí" : "No"}</td>
                <td>
                  <Button variant="warning" size="sm" onClick={() => handleShowModal(convenio)}>Editar</Button>{' '}
                  <Button variant="danger" size="sm" onClick={() => handleDeactivate(convenio.id)}>Desactivar</Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">No hay convenios disponibles.</td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Button to add a new convenio */}
      <Button variant="success" onClick={() => handleShowModal()}>Agregar Convenio</Button>

      {/* Modal for adding/editing convenios */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{currentConvenio ? "Editar Convenio" : "Agregar Convenio"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formTitulo">
              <Form.Label>Título</Form.Label>
              <Form.Control
                type="text"
                name="titulo"
                value={formData.titulo}
                onChange={handleChange}
                required
                placeholder="Ingrese el título del convenio"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDescripcion">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="descripcion"
                value={formData.descripcion}
                onChange={handleChange}
                required
                placeholder="Ingrese la descripción del convenio"
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              {currentConvenio ? "Guardar Cambios" : "Crear Convenio"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ConveniosPage;
