import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  
  const isAuthenticated  = useSelector((state) => state.auth.isAuthenticated);
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const cambioClave = useSelector((state) => state.auth.cambioClave); 

  // Si el usuario debe cambiar la contraseña, redirigir a la página de cambio de clave
  if (cambioClave) {
    return <Navigate to="/cambio-clave" />;
  }


  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (!isAdmin) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
