import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../redux/authSlice";
import { Card, Form, Button, Alert, Container, Row, Col } from "react-bootstrap";

const ResetPasswordComponent = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validationErrors, setValidationErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { globalMessage } = useSelector((state) => state.auth);

  const validatePassword = () => {
    const errors = [];
    if (password.length < 8)
      errors.push("La contraseña debe tener al menos 8 caracteres.");
    if (!/[a-z]/.test(password))
      errors.push("La contraseña debe incluir al menos una letra minúscula.");
    if (!/[A-Z]/.test(password))
      errors.push("La contraseña debe incluir al menos una letra mayúscula.");
    if (!/[0-9]/.test(password))
      errors.push("La contraseña debe incluir al menos un número.");
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password))
      errors.push("La contraseña debe incluir al menos un carácter especial.");
    if (password !== confirmPassword)
      errors.push("Las contraseñas no coinciden.");
    setValidationErrors(errors);
    return errors.length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validatePassword()) {
      try {
        await dispatch(resetPassword({ token, password })).unwrap();
        setSuccessMessage("Contraseña restablecida con éxito. Redirigiendo...");
        setTimeout(() => navigate("/login"), 3500); // Redirigir al inicio tras 3,5 segundos
      } catch (error) {
        setValidationErrors([error.message || "Error al restablecer contraseña."]);
      }
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <Row className="w-100">
        <Col md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
          <Card className="shadow-lg">
            <Card.Body>
              <h2 className="text-center mb-4 text-primary">Restablecer Contraseña</h2>
              <Form onSubmit={handleSubmit} noValidate>
                <Form.Group controlId="password" className="mb-3">
                  <Form.Label>Nueva Contraseña</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Introduce tu nueva contraseña"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    isInvalid={validationErrors.length > 0}
                  />
                  <Form.Text className="text-muted">
                    La contraseña debe tener al menos 8 caracteres, incluir una letra mayúscula, 
                    una letra minúscula, un número y un carácter especial (por ejemplo, @, #, $).
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="confirmPassword" className="mb-3">
                  <Form.Label>Confirmar Contraseña</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirma tu nueva contraseña"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    isInvalid={validationErrors.length > 0}
                  />
                  <Form.Text className="text-muted">
                    Asegúrate de que coincida con la contraseña ingresada anteriormente.
                  </Form.Text>
                </Form.Group>
                {validationErrors.length > 0 && (
                  <Alert variant="danger">
                    <ul className="mb-0">
                      {validationErrors.map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  </Alert>
                )}
                {successMessage && (
                  <Alert variant="success" className="mt-3 text-center">
                    {successMessage}
                  </Alert>
                )}
                {globalMessage && (
                  <Alert variant="info" className="mt-3 text-center">
                    {globalMessage}
                  </Alert>
                )}
                <Button variant="primary" type="submit" className="w-100 mt-3">
                  Restablecer Contraseña
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPasswordComponent;
