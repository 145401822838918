import React, { useState, useEffect } from 'react';
import api from '../utils/axiosConfig';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout, setGlobalMessage } from '../redux/authSlice';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';

const CambioContrasenaPage = () => {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [emailPersonal, setEmailPersonal] = useState('');
  const [contactoEmergencia, setContactoEmergencia] = useState('');
  const [telefonoEmergencia, setTelefonoEmergencia] = useState('');
  const [alert, setAlert] = useState(null);
  const [error, setError] = useState(null);
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [telefonoEmergenciaError, setTelefonoEmergenciaError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.auth.token);
  const usuario = useSelector((state) => state.auth.usuario);
  const globalMessage = useSelector((state) => state.auth.globalMessage);


  const isValidChileanPhoneNumber = (phoneNumber) => {
    const chileanPhoneRegex = /^\+56\d{9}$/;
    return chileanPhoneRegex.test(phoneNumber);
  };





  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) errors.push('La contraseña debe tener al menos 8 caracteres');
    if (!/[a-z]/.test(password)) errors.push('Debe incluir al menos una letra minúscula');
    if (!/[A-Z]/.test(password)) errors.push('Debe incluir al menos una letra mayúscula');
    if (!/[0-9]/.test(password)) errors.push('Debe incluir al menos un número');
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) errors.push('Debe incluir al menos un carácter especial');
    return errors;
  };

  useEffect(() => {
    // Verificar si los datos están incompletos y establecer el mensaje global
    if (!usuario.email_personal || !usuario.contacto_emergencia || !usuario.telefono_emergencia) {
      dispatch(setGlobalMessage('Por favor, actualiza tus datos personales para continuar.'));
    }
  }, [usuario, dispatch]);

  useEffect(() => {
    setPasswordErrors(validatePassword(password));
  }, [password]);

  // Verificar teléfono en tiempo real
  useEffect(() => {
    if (telefonoEmergencia && !isValidChileanPhoneNumber(telefonoEmergencia)) {
      setTelefonoEmergenciaError('El número de teléfono debe ser válido y en formato +56XXXXXXXXX.');
    } else {
      setTelefonoEmergenciaError(null);
    }
  }, [telefonoEmergencia]);

  const handleUpdateDetails = async (e) => {
    e.preventDefault();

    if (passwordErrors.length > 0) {
      setError('Por favor, corrige los errores en la contraseña antes de continuar.');
      return;
    }

    if (password !== passwordConfirm) {
      setError('Las contraseñas no coinciden');
      return;
    }

    try {
      const response = await api.post(
        '/auth/update-details',
        {
          newPassword: password,
          emailPersonal,
          contactoEmergencia,
          telefonoEmergencia,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAlert({
        type: 'success',
        message: response.data.message || 'Datos actualizados con éxito. Tu sesión se cerrará automáticamente.',
      });

      setTimeout(() => {
        dispatch(logout());
        navigate('/login');
      }, 3000);
    } catch (error) {
      console.error('Error al actualizar los detalles:', error);
      setAlert({
        type: 'danger',
        message: error.response?.data?.error || 'Error al actualizar los datos',
      });
    }
  };

  return (
    <div className="d-flex justify-content-center mt-5">
      <Card style={{ width: '100%', maxWidth: '500px', padding: '20px' }}>
        <Card.Body>
          {globalMessage && (
            <Alert variant="warning" onClose={() => dispatch(setGlobalMessage(null))} dismissible>
              {globalMessage}
            </Alert>
          )}
          {alert && (
            <Alert variant={alert.type} onClose={() => setAlert(null)} dismissible>
              {alert.message}
            </Alert>
          )}
          <p style={{ fontSize: '18px', fontWeight: '500', color: '#333' }}>
            ¡Hola! Es necesario que completes tus datos y cambies tu contraseña.
          </p>
          <Form noValidate onSubmit={handleUpdateDetails}>
            <Form.Group controlId="formPassword" className="mb-3">
              <Form.Label>Contraseña Nueva</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="password"
                  placeholder="Nueva contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </InputGroup>
              <Form.Text className="text-muted">
                La contraseña debe tener al menos 8 caracteres, incluir una letra mayúscula, una minúscula, un número y un carácter especial.
              </Form.Text>
              {passwordErrors.length > 0 && (
                <ul style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                  {passwordErrors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              )}
            </Form.Group>

            <Form.Group controlId="formPasswordConfirm" className="mb-3">
              <Form.Label>Confirmar Contraseña</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="password"
                  placeholder="Confirmar contraseña"
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                  required
                />
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="formEmailPersonal" className="mb-3">
              <Form.Label>Email Personal</Form.Label>
              <Form.Control
                type="email"
                placeholder="Ingresa tu email personal"
                value={emailPersonal}
                onChange={(e) => setEmailPersonal(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formContactoEmergencia" className="mb-3">
              <Form.Label>Contacto de Emergencia</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre del contacto"
                value={contactoEmergencia}
                onChange={(e) => setContactoEmergencia(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formTelefonoEmergencia" className="mb-3">
              <Form.Label>Teléfono de Emergencia</Form.Label>
              <Form.Control
                type="text"
                placeholder="Teléfono del contacto"
                value={telefonoEmergencia}
                onChange={(e) => setTelefonoEmergencia(e.target.value)}
                required
              />
              {telefonoEmergenciaError && (
                <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                  {telefonoEmergenciaError}
                </div>
              )}
            </Form.Group>
            {error && <Alert variant="danger">{error}</Alert>}
            <Button type="submit" variant="primary" style={{ width: '100%', padding: '10px', fontSize: '16px' }}>
              Guardar Datos y Cambiar Contraseña
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CambioContrasenaPage;
