import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  enviarCorreoMasivo,
  obtenerHistorialCorreos,
  eliminarCorreo,
  clearSuccessMessage,
} from "../../redux/correosSlice";
import { Button, Modal, Form, Table, Spinner, Alert, ProgressBar } from "react-bootstrap";
import api from "../../utils/axiosConfig";

const CorreoMasivoPage = () => {
  const dispatch = useDispatch();
  const { correos, loading, error, successMessage } = useSelector(
    (state) => state.correo
  );

  const [showModal, setShowModal] = useState(false);
  const [correoDetails, setCorreoDetails] = useState({
    asunto: "",
    mensaje: "",
  });
  const [validationErrors, setValidationErrors] = useState([]);
  const [progress, setProgress] = useState(0); // Estado para manejar el progreso
  const [sending, setSending] = useState(false); // Estado para saber si el envío está en curso

  useEffect(() => {
    dispatch(obtenerHistorialCorreos());
  }, [dispatch]);

  const handleCloseModal = () => {
    setShowModal(false);
    setCorreoDetails({ asunto: "", mensaje: "" });
    setValidationErrors([]);
  };

  const handleShowModal = () => {
    setShowModal(true);
    setValidationErrors([]);
  };

  const handleInputChange = (e) => {
    setCorreoDetails({ ...correoDetails, [e.target.name]: e.target.value });
  };

  const handleSendCorreo = async () => {
    const errors = [];
    if (!correoDetails.asunto) errors.push("El asunto es obligatorio.");
    if (!correoDetails.mensaje) errors.push("El mensaje es obligatorio.");
    if (correoDetails.mensaje.length <= 10)
      errors.push("El mensaje debe tener más de 10 caracteres.");
  
    if (errors.length > 0) {
      setValidationErrors(errors);
      return;
    }
  
    try {
      setSending(true);
      setProgress(0);
      await dispatch(enviarCorreoMasivo(correoDetails)).unwrap();
  
      const interval = setInterval(async () => {
        try {
          const response = await api.get("/api/correo/progreso");
          const { total, completed } = response.data;
  
          if (total === 0) {
            console.warn("Progreso no disponible. Deteniendo...");
            clearInterval(interval);
            setSending(false);
            return;
          }
  
          const progressPercentage = Math.round((completed / total) * 100);
          setProgress(progressPercentage);
  
          if (completed >= total) {
            console.log("Progreso completado:", { total, completed });
            clearInterval(interval);
            setSending(false);
            dispatch(obtenerHistorialCorreos());
          }
        } catch (err) {
          console.error("Error al consultar progreso:", err);
          clearInterval(interval);
          setSending(false);
        }
      }, 1000);
    } catch (err) {
      console.error("Error al enviar el correo:", err);
      setSending(false);
    }
  };
  

  const handleDeleteCorreo = async (id) => {
    if (window.confirm("¿Estás seguro de eliminar este correo?")) {
      try {
        await dispatch(eliminarCorreo(id)).unwrap();
        dispatch(obtenerHistorialCorreos());
      } catch (err) {
        console.error("Error al eliminar el correo:", err);
      }
    }
  };

  return (
    <div>
      <h1>Gestión de Correos Masivos</h1>

      <Button onClick={handleShowModal} className="mb-3">
        Enviar Correo Masivo
      </Button>

      {loading && <Spinner animation="border" />}

      {successMessage && (
        <Alert
          variant="success"
          onClose={() => dispatch(clearSuccessMessage())}
          dismissible
        >
          {successMessage}
        </Alert>
      )}

      {error && <Alert variant="danger">{error}</Alert>}

      {/* Barra de progreso */}
      {sending && (
        <div className="mb-3">
          <ProgressBar animated now={progress} label={`${progress}%`} />
        </div>
      )}

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Asunto</th>
            <th>Mensaje</th>
            <th>Estado</th>
            <th>Fecha de Envío</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {correos.map((correo, index) => (
            <tr key={correo.id || index}>
              <td>{index + 1}</td>
              <td>{correo.asunto}</td>
              <td>{correo.mensaje}</td>
              <td>{correo.estado}</td>
              <td>{new Date(correo.fecha_envio).toLocaleString("es-CL")}</td>
              <td>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteCorreo(correo.id)}
                >
                  Eliminar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal para enviar correo masivo */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Enviar Correo Masivo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {validationErrors.length > 0 && (
            <Alert variant="danger">
              <ul>
                {validationErrors.map((err, idx) => (
                  <li key={idx}>{err}</li>
                ))}
              </ul>
            </Alert>
          )}
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Asunto</Form.Label>
              <Form.Control
                type="text"
                name="asunto"
                value={correoDetails.asunto}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Mensaje</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                name="mensaje"
                value={correoDetails.mensaje}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSendCorreo}>
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CorreoMasivoPage;
