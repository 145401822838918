import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBeneficios, createBeneficio, deleteBeneficio } from "../../redux/beneficiosSlice";
import { Button, Modal, Form, Table, Alert } from "react-bootstrap";

const BeneficiosAdminPage = () => {
  const dispatch = useDispatch();
  const { beneficios, loading, error } = useSelector((state) => state.beneficios);

  const [showModal, setShowModal] = useState(false);
  const [currentBeneficio, setCurrentBeneficio] = useState({
    nombre: "",
    descripcion: "",
    fecha_inicio: "",
    fecha_fin: "",
  });
  const [validationErrors, setValidationErrors] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [deleteError, setDeleteError] = useState(null);

  const handleClose = () => {
    setShowModal(false);
    setValidationErrors([]);
  };

  const handleShow = () => {
    setCurrentBeneficio({
      nombre: "",
      descripcion: "",
      fecha_inicio: "",
      fecha_fin: "",
    });
    setValidationErrors([]);
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    setCurrentBeneficio({
      ...currentBeneficio,
      [e.target.name]: e.target.value,
    });
  };

  const handleCreateBeneficio = async () => {
    setValidationErrors([]);
    setIsSaving(true);

    // Validaciones locales antes de enviar al backend
    const errors = [];
    if (!currentBeneficio.nombre) errors.push("El nombre del beneficio es obligatorio.");
    if (currentBeneficio.nombre.length > 255) errors.push("El nombre no debe superar los 255 caracteres.");
    if (currentBeneficio.descripcion && currentBeneficio.descripcion.length < 10) {
      errors.push("La descripción debe tener al menos 10 caracteres.");
    }

    // Validación de fecha_inicio
    if (!currentBeneficio.fecha_inicio) errors.push("La fecha de inicio es obligatoria.");
    if (currentBeneficio.fecha_inicio && new Date(currentBeneficio.fecha_inicio).toString() === 'Invalid Date') {
      errors.push("La fecha de inicio no es válida.");
    }

    // Validación de fecha_inicio no en el pasado
    if (currentBeneficio.fecha_inicio && new Date(currentBeneficio.fecha_inicio) < new Date()) {
      errors.push("La fecha de inicio no puede ser en el pasado.");
    }

    // Validación de fecha_fin
    if (currentBeneficio.fecha_fin && new Date(currentBeneficio.fecha_fin).toString() === 'Invalid Date') {
      errors.push("La fecha de fin no es válida.");
    }

    if (
      currentBeneficio.fecha_fin &&
      new Date(currentBeneficio.fecha_fin) <= new Date(currentBeneficio.fecha_inicio)
    ) {
      errors.push("La fecha de fin debe ser posterior a la fecha de inicio.");
    }

    if (errors.length > 0) {
      setValidationErrors(errors);
      setIsSaving(false);
      return;
    }

    // Intentar crear el beneficio
    try {
      await dispatch(createBeneficio(currentBeneficio)).unwrap();
      dispatch(fetchBeneficios());
      handleClose();
    } catch (error) {
      console.error("Error al crear el beneficio:", error);
      setValidationErrors(["Error al crear el beneficio. Por favor, inténtalo nuevamente."]);
    } finally {
      setIsSaving(false);
    }
  };



  const handleDeleteBeneficio = async (id) => {
    setDeleteError(null);
    try {
      await dispatch(deleteBeneficio(id)).unwrap();
      dispatch(fetchBeneficios());
    } catch (error) {
      console.error("Error al eliminar el beneficio:", error);
      setDeleteError("Error al eliminar el beneficio. Por favor, inténtalo nuevamente.");
    }
  };

  const formatFecha = (fecha) => {
    const date = new Date(fecha);
    if (isNaN(date.getTime())) {
      return "Fecha no válida";
    }
    return new Intl.DateTimeFormat("es-CL", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
  };
  

  useEffect(() => {
    dispatch(fetchBeneficios());
  }, [dispatch]);

  return (
    <div>
      <h1>Gestión de Beneficios</h1>

      <Button onClick={handleShow} style={{ marginBottom: "20px" }}>
        Crear Nuevo Beneficio
      </Button>

      {loading ? (
        <p>Cargando beneficios...</p>
      ) : error ? (
        <Alert variant="danger">Error: {error}</Alert>
      ) : (
        <>
          {deleteError && <Alert variant="danger">{deleteError}</Alert>}
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>Descripción</th>
                <th>Fecha Inicio</th>
                <th>Fecha Fin</th>
                <th>Activo</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {beneficios.map((beneficio) => (
                <tr key={beneficio.id}>
                  <td>{beneficio.id}</td>
                  <td>{beneficio.nombre}</td>
                  <td>{beneficio.descripcion}</td>
                  <td>{formatFecha(beneficio.fecha_inicio)}</td>
                  <td>{formatFecha(beneficio.fecha_fin)}</td>
                  <td>{beneficio.activo ? "Sí" : "No"}</td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDeleteBeneficio(beneficio.id)}
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}

      {/* Modal para Crear/Editar Beneficio */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Crear Beneficio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {validationErrors.length > 0 && (
            <Alert variant="danger">
              <ul>
                {validationErrors.map((err, idx) => (
                  <li key={idx}>{err}</li>
                ))}
              </ul>
            </Alert>
          )}
          <Form>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                name="nombre"
                value={currentBeneficio.nombre}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                name="descripcion"
                value={currentBeneficio.descripcion}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Fecha Inicio</Form.Label>
              <Form.Control
                type="date"
                name="fecha_inicio"
                value={currentBeneficio.fecha_inicio}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Fecha Fin</Form.Label>
              <Form.Control
                type="date"
                name="fecha_fin"
                value={currentBeneficio.fecha_fin}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleCreateBeneficio} disabled={isSaving}>
            {isSaving ? "Guardando..." : "Guardar"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BeneficiosAdminPage;
