// regionComunaSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/axiosConfig';

// Obtener todas las regiones
export const fetchRegiones = createAsyncThunk('regiones/fetchRegiones', async () => {
  try {
      const response = await api.get('/api/dir/regiones');
      console.log('Regiones desde el backend:', response.data);
      return response.data;
  } catch (error) {
      console.error('Error al obtener regiones:', error);
      throw error;
  }
});

  
// Obtener comunas por región
export const fetchComunasByRegion = createAsyncThunk('regiones/fetchComunasByRegion', async (regionId) => {
  const response = await api.get(`/api/dir/comunas?region_id=${regionId}`);
  console.log("Comunas desde el backend:", response.data);
  return response.data;
});

const regionComunaSlice = createSlice({
  name: 'regionComuna',
  initialState: {
    regiones: [],
    comunas: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegiones.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRegiones.fulfilled, (state, action) => {
        state.regiones = action.payload;
        state.loading = false;
      })
      .addCase(fetchComunasByRegion.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchComunasByRegion.fulfilled, (state, action) => {
        state.comunas = action.payload || [];
        state.loading = false;
      })
      .addCase(fetchRegiones.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchComunasByRegion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default regionComunaSlice.reducer;
