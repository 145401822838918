import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEncuestasPendientes, responderEncuesta } from '../../redux/encuestasSlice';
import { Button, Modal, Form, Alert, Card, Container, Row, Col, Spinner } from 'react-bootstrap';

const RencuestaPage = () => {
  const dispatch = useDispatch();
  const { encuestasPendientes = [], loading, error } = useSelector((state) => state.encuestas);

  const [showModal, setShowModal] = useState(false);
  const [selectedEncuesta, setSelectedEncuesta] = useState(null);
  const [preguntas, setPreguntas] = useState([]);
  const [respuestas, setRespuestas] = useState({});
  const [responseError, setResponseError] = useState(null);

  useEffect(() => {
    dispatch(fetchEncuestasPendientes()).then((response) => {
      if (Array.isArray(response) && response.length === 0) {
        console.log('No hay encuestas pendientes para el usuario');
      }
    });
  }, [dispatch]);

  const handleOpenModal = (encuesta) => {
    setSelectedEncuesta(encuesta);
    setPreguntas(encuesta.PreguntasEncuestas || []);
    setRespuestas({});
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedEncuesta(null);
    setPreguntas([]);
    setRespuestas({});
    setResponseError(null);
    dispatch(fetchEncuestasPendientes());
  };

  const handleRespuestaChange = (preguntaId, value) => {
    setRespuestas((prev) => ({
      ...prev,
      [preguntaId]: value,
    }));
  };

  const handleEnviarRespuestas = () => {
    if (!selectedEncuesta) return;

    const respuestasArray = Object.entries(respuestas).map(([preguntaId, respuesta]) => ({
      pregunta_id: parseInt(preguntaId, 10),
      respuesta,
    }));

    const promises = respuestasArray.map((respuesta) =>
      dispatch(
        responderEncuesta({
          encuestaId: selectedEncuesta.id,
          respuesta: { pregunta_id: respuesta.pregunta_id, respuesta: respuesta.respuesta },
        })
      ).unwrap()
    );

    Promise.all(promises)
      .then(() => {
        alert('Respuestas enviadas exitosamente.');
        dispatch(fetchEncuestasPendientes());
        handleCloseModal();
      })
      .catch((err) => {
        console.error('Error al enviar respuestas:', err);
        setResponseError('Ocurrió un error al enviar las respuestas.');
      });
  };

  return (
    <Container className="mt-4">
      <h1 className="mb-4 text-center">Encuestas Pendientes</h1>
      {loading && (
        <div className="text-center">
          <Spinner animation="border" />
          <p>Cargando encuestas...</p>
        </div>
      )}
      {error && <Alert variant="danger">Error: {error}</Alert>}
      {!loading && encuestasPendientes.length === 0 && (
        <Alert variant="info">No tienes encuestas pendientes.</Alert>
      )}
      <Row>
        {!loading &&
          encuestasPendientes.map((encuesta) => (
            <Col md={6} lg={4} key={encuesta.id} className="mb-4">
              <Card>
                <Card.Body>
                  <Card.Title>{encuesta.titulo}</Card.Title>
                  <Card.Text>{encuesta.descripcion}</Card.Text>
                  <Button variant="primary" onClick={() => handleOpenModal(encuesta)}>
                    Responder
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
      </Row>

      <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Responder Encuesta: {selectedEncuesta?.titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {responseError && <Alert variant="danger">{responseError}</Alert>}
          {preguntas.length > 0 ? (
            preguntas.map((pregunta) => (
              <Form.Group className="mb-3" key={pregunta.id}>
                <Form.Label>{pregunta.pregunta}</Form.Label>
                {pregunta.tipo_respuesta === 'opcion_multiple' ? (
                  <div>
                    {pregunta.alternativas.map((alternativa, index) => (
                      <Form.Check
                        type="radio"
                        key={index}
                        label={alternativa}
                        name={`pregunta-${pregunta.id}`}
                        value={alternativa}
                        checked={respuestas[pregunta.id] === alternativa}
                        onChange={(e) => handleRespuestaChange(pregunta.id, e.target.value)}
                      />
                    ))}
                  </div>
                ) : (
                  <Form.Control
                    type="text"
                    placeholder="Ingrese su respuesta"
                    onChange={(e) => handleRespuestaChange(pregunta.id, e.target.value)}
                  />
                )}
              </Form.Group>
            ))
          ) : (
            <p>No hay preguntas para esta encuesta.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={handleEnviarRespuestas}
            disabled={Object.keys(respuestas).length < preguntas.length}
          >
            Enviar Respuestas
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default RencuestaPage;
