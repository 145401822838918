import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/axiosConfig';

// Thunks para acciones asincrónicas

// Obtener todas las encuestas (activas/inactivas)
export const fetchEncuestas = createAsyncThunk(
  'encuestas/fetchEncuestas',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/api/encuestas/getencuestas');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error desconocido');
    }
  }
);


// Crear una nueva encuesta
export const crearEncuesta = createAsyncThunk(
  'encuestas/crearEncuesta',
  async (encuesta, { rejectWithValue }) => {
    try {
      const response = await api.post('/api/encuestas/crear', encuesta);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error desconocido');
    }
  }
);

// Obtener una encuesta específica por ID
export const fetchEncuestaById = createAsyncThunk(
  'encuestas/fetchEncuestaById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.get(`/api/encuestas/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error desconocido');
    }
  }
);


// Añadir una nueva pregunta a una encuesta
export const crearPregunta = createAsyncThunk(
  'encuestas/crearPregunta',
  async ({ encuestaId, pregunta, tipo_respuesta, alternativas }, { rejectWithValue }) => {
    try {
      const response = await api.post(`/api/encuestas/${encuestaId}/preguntas`, {
        pregunta,
        tipo_respuesta,
        alternativas,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error desconocido');
    }
  }
);

// Eliminar una pregunta de una encuesta
export const eliminarPregunta = createAsyncThunk(
  'encuestas/eliminarPregunta',
  async (id, { rejectWithValue }) => {
    try {
      await api.delete(`/api/encuestas/preguntas/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error desconocido');
    }
  }
);

// Eliminar una encuesta
export const eliminarEncuesta = createAsyncThunk(
  'encuestas/eliminarEncuesta',
  async (id, { rejectWithValue }) => {
    try {
      await api.delete(`/api/encuestas/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error desconocido');
    }
  }
);

// Obtener respuestas de una encuesta específica
export const fetchRespuestas = createAsyncThunk(
  'encuestas/fetchRespuestas',
  async (encuestaId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/api/encuestas/${encuestaId}/respuestas`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error desconocido');
    }
  }
);

// Responder a una encuesta
export const responderEncuesta = createAsyncThunk(
  'encuestas/responderEncuesta',
  async ({ encuestaId, respuesta }, { rejectWithValue }) => {
    try {
      const response = await api.post(`/api/encuestas/${encuestaId}/respuestas`, respuesta);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error desconocido');
    }
  }
);


// Obtener preguntas de una encuesta específica
export const fetchPreguntasByEncuestaId = createAsyncThunk(
  'encuestas/fetchPreguntasByEncuestaId',
  async (encuestaId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/api/encuestas/${encuestaId}/preguntas`);
      return { encuestaId, preguntas: response.data.preguntas };
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error desconocido');
    }
  }
);

export const fetchEncuestasPendientes = createAsyncThunk(
  'encuestas/fetchEncuestasPendientes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/api/encuestas/getencuestas/activas', {
        params: { t: new Date().getTime() }
      });
      console.log('Datos de las encuestas activas:', response.data);
      if (response.data?.data?.length === 0) {
        // Si no hay encuestas, devolver un estado manejable
        return [];
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error al obtener encuestas activas');
    }
  }
);





// Slice
const encuestasSlice = createSlice({
  name: 'encuestas',
  initialState: {
    encuestas: [],
    preguntas: {},
    encuesta: null,
    respuestas: [],
    encuestasPendientes: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPreguntasByEncuestaId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPreguntasByEncuestaId.fulfilled, (state, action) => {
        console.log('Preguntas recibidas para encuesta', action.payload.encuestaId, ':', action.payload.preguntas);
        state.loading = false;
        const { encuestaId, preguntas } = action.payload;
        state.preguntas[encuestaId] = preguntas;
      })
      .addCase(fetchPreguntasByEncuestaId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchEncuestas.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEncuestas.fulfilled, (state, action) => {
        state.loading = false;
        state.encuestas = action.payload;
      })
      .addCase(fetchEncuestas.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(crearEncuesta.fulfilled, (state, action) => {
        state.encuestas.push(action.payload);
      })
      .addCase(crearEncuesta.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(fetchEncuestaById.fulfilled, (state, action) => {
        state.encuesta = action.payload;
      })
      .addCase(fetchEncuestaById.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(crearPregunta.fulfilled, (state, action) => {
        const nuevaPregunta = action.payload;

        // Si la encuesta actual está cargada, añade la nueva pregunta
        if (state.encuesta && state.encuesta.PreguntasEncuestas) {
          state.encuesta.PreguntasEncuestas.push(nuevaPregunta);
        }

        // Si las preguntas están en el estado global por encuesta, actualízalas
        if (nuevaPregunta.encuesta_id in state.preguntas) {
          state.preguntas[nuevaPregunta.encuesta_id].push(nuevaPregunta);
        }
      })
      .addCase(crearPregunta.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(eliminarPregunta.fulfilled, (state, action) => {
        if (state.encuesta && state.encuesta.PreguntasEncuestas) {
          state.encuesta.PreguntasEncuestas = state.encuesta.PreguntasEncuestas.filter(
            (pregunta) => pregunta.id !== action.payload
          );
        }
      })
      .addCase(eliminarPregunta.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(eliminarEncuesta.fulfilled, (state, action) => {
        state.encuestas = state.encuestas.filter(
          (encuesta) => encuesta.id !== action.payload
        );
      })
      .addCase(eliminarEncuesta.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(fetchRespuestas.fulfilled, (state, action) => {
        state.respuestas = action.payload;
      })
      .addCase(fetchRespuestas.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(responderEncuesta.fulfilled, (state, action) => {
        state.respuestas.push(action.payload);
        state.encuestasPendientes = state.encuestasPendientes.filter(
          (encuesta) => encuesta.id !== action.meta.arg.encuestaId
        );
      })
      .addCase(responderEncuesta.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(fetchEncuestasPendientes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEncuestasPendientes.fulfilled, (state, action) => {
        console.log('Encuestas pendientes recibidas:', action.payload); // Verifica los datos que recibes
        state.loading = false;
        state.encuestasPendientes = action.payload;
      })
      .addCase(fetchEncuestasPendientes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Error al obtener encuestas activas';
        state.encuestasPendientes = []; // Limpiar encuestas en caso de error
      });
  },
});

export default encuestasSlice.reducer;
