import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/authSlice';
import api from '../../utils/axiosConfig';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Placeholder from 'react-bootstrap/Placeholder';
import '../../styles/LoginComponent.css';

const LoginPlaceholder = () => (
  <div className="d-flex justify-content-center align-items-center vh-100">
    <div style={{ width: '300px' }}>
      <Placeholder as={Form.Label} animation="glow">
        <Placeholder xs={4} />
      </Placeholder>
      <Placeholder as={Form.Control} animation="glow" style={{ height: '38px' }} />
      <div className="mb-3" />
      <Placeholder as={Form.Label} animation="glow">
        <Placeholder xs={6} />
      </Placeholder>
      <Placeholder as={Form.Control} animation="glow" style={{ height: '38px' }} />
      <div className="mt-4">
        <Placeholder.Button variant="primary" xs={12} />
      </div>
    </div>
  </div>
);

const LoginComponent = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Control de carga
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Validación de email
  const validateEmail = () => {
    if (!email) {
      setErrors((prev) => ({ ...prev, email: 'El email es requerido.' }));
      return false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setErrors((prev) => ({ ...prev, email: 'El email no es válido.' }));
      return false;
    }
    setErrors((prev) => ({ ...prev, email: null }));
    return true;
  };

  // Validación de contraseña
  const validatePassword = () => {
    if (!password) {
      setErrors((prev) => ({ ...prev, password: 'La contraseña es requerida.' }));
      return false;
    } else if (password.length < 8) {
      setErrors((prev) => ({ ...prev, password: 'La contraseña debe tener al menos 8 caracteres.' }));
      return false;
    } else if (!/[a-z]/.test(password)) {
      setErrors((prev) => ({ ...prev, password: 'La contraseña debe incluir al menos una letra minúscula.' }));
      return false;
    } else if (!/[A-Z]/.test(password)) {
      setErrors((prev) => ({ ...prev, password: 'La contraseña debe incluir al menos una letra mayúscula.' }));
      return false;
    } else if (!/[0-9]/.test(password)) {
      setErrors((prev) => ({ ...prev, password: 'La contraseña debe incluir al menos un número.' }));
      return false;
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      setErrors((prev) => ({ ...prev, password: 'La contraseña debe incluir al menos un carácter especial.' }));
      return false;
    }
    setErrors((prev) => ({ ...prev, password: null }));
    return true;
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    const isEmailValid = validateEmail();
    const isPasswordValid = validatePassword();

    if (!isEmailValid || !isPasswordValid) {
      setAlert({ type: 'danger', message: 'Por favor, corrige los errores en el formulario.' });
      return;
    }

    setIsLoading(true); // Mostrar placeholder mientras se procesa el login
    try {
      const response = await api.post('/auth/login', { email, password });

      const { message, token, rol, nombre, apellido, cambio_clave } = response.data;

      // Guardar datos en Redux y localStorage
      dispatch(login({ token, rol, nombre, apellido }));

      localStorage.setItem('token', token);
      localStorage.setItem('rol', rol);
      localStorage.setItem('nombre', nombre);
      localStorage.setItem('apellido', apellido);

      setAlert({ type: 'success', message: message || 'Login exitoso.' });

      if (cambio_clave) {
        navigate('/cambio-clave');
        return;
      }

      navigate('/');
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const errorMessage =
          status === 400
            ? 'Credenciales inválidas. Por favor, revisa los campos e intenta nuevamente.'
            : status === 404 || status === 401
            ? 'Usuario o contraseña incorrectos.'
            : 'Error inesperado. Por favor, intenta más tarde.';

        setAlert({ type: 'danger', message: errorMessage });
      } else {
        setAlert({
          type: 'danger',
          message: 'Error de red, por favor intente más tarde.',
        });
      }
    } finally {
      setIsLoading(false); // Dejar de mostrar placeholder
    }
  };

  return (
    <div>
      {isLoading ? (
        <LoginPlaceholder />
      ) : (
        <div>
          {alert && (
            <Alert variant={alert.type} onClose={() => setAlert(null)} dismissible>
              {alert.message}
            </Alert>
          )}

          <Form onSubmit={handleLogin} noValidate>
            {/* Email Input */}
            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>Correo Electrónico</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text>@</InputGroup.Text>
                <Form.Control
                  type="email"
                  placeholder="Ingresa tu correo"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={validateEmail}
                  isInvalid={!!errors.email}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            {/* Password Input */}
            <Form.Group controlId="formPassword" className="mb-3">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                type="password"
                placeholder="Ingresa tu contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={validatePassword}
                isInvalid={!!errors.password}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>

            <Button type="submit" variant="primary">Iniciar Sesión</Button>
          </Form>
        </div>
      )}
    </div>
  );
};

export default LoginComponent;
