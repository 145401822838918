import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestPasswordRecovery } from '../../redux/authSlice';
import { Form, Button, Alert, Container, Row, Col, Card } from 'react-bootstrap';

const RecoverPasswordComponent = () => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const { recoveryMessage, error } = useSelector((state) => state.auth);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!email) {
      newErrors.email = 'El correo electrónico es obligatorio.';
    } else if (!validateEmail(email)) {
      newErrors.email = 'Por favor, introduce un correo válido.';
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      dispatch(requestPasswordRecovery(email.trim()));
    }
  };

  useEffect(() => {
    if (error) {
      console.error('Error al solicitar recuperación:', error);
    }
  }, [recoveryMessage, error]);

  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100 bg-light">
      <Row className="w-100">
        <Col md={6} lg={4} className="mx-auto">
          <Card className="shadow">
            <Card.Body>
              <h3 className="text-center text-primary mb-4">Recuperar Contraseña</h3>
              <p className="text-center text-muted">
                Ingrese su correo electrónico para recuperar su cuenta.
              </p>
              <Form onSubmit={handleSubmit} noValidate>
                <Form.Group controlId="formEmail">
                  <Form.Label>Correo Electrónico</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Introduce tu correo electrónico"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100 mt-3">
                  Recuperar Contraseña
                </Button>
              </Form>
              {recoveryMessage && (
                <Alert variant="success" className="mt-4 text-center">
                  Si su email está registrado, se enviará un correo. Verifique su casilla de correos recibidos.
                </Alert>
              )}
              {error && (
                <Alert variant="danger" className="mt-4 text-center">
                  {error}
                </Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default RecoverPasswordComponent;
