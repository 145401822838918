import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchEncuestas, crearEncuesta, eliminarEncuesta, fetchPreguntasByEncuestaId } from '../../redux/encuestasSlice';
import { Table, Button, Modal, Form, Alert } from 'react-bootstrap';
import api from '../../utils/axiosConfig';

const EncuestasPage = () => {
  const dispatch = useDispatch();
  const { encuestas, error, preguntas } = useSelector((state) => state.encuestas);

  const [modalState, setModalState] = useState({
    showEncuestaModal: false,
    showPreguntaModal: false,
  });
  const [encuestaForm, setEncuestaForm] = useState({ titulo: '', descripcion: '' });
  const [preguntaForm, setPreguntaForm] = useState({
    pregunta: '',
    tipoRespuesta: 'opcion_multiple',
    alternativas: [''],
  });
  const [selectedEncuestaId, setSelectedEncuestaId] = useState(null);
  const [editMode, setEditMode] = useState({ isEditing: false, preguntaId: null });
  const [errors, setErrors] = useState({ encuesta: null, pregunta: null });

  useEffect(() => {
    dispatch(fetchEncuestas());
  }, [dispatch]);

  const handleShowModal = (modalName, state) => {
    setModalState((prev) => ({ ...prev, [modalName]: state }));
  };

  const handleChange = (setter) => (e) => {
    const { name, value } = e.target;
    setter((prev) => ({ ...prev, [name]: value }));
  };

  const handleCrearEncuesta = (e) => {
    e.preventDefault();

    // Validación para el título
    if (encuestaForm.titulo.length <= 10) {
      setErrors((prev) => ({ ...prev, encuesta: 'El título debe tener más de 10 caracteres.' }));
      return;
    }

    // Validación para la descripción
    if (encuestaForm.descripcion && encuestaForm.descripcion.length > 500) {
      setErrors((prev) => ({ ...prev, encuesta: 'La descripción no debe exceder los 500 caracteres.' }));
      return;
    }

    // Si las validaciones pasan, proceder con el envío del formulario
    dispatch(crearEncuesta({ ...encuestaForm, activo: true }))
      .unwrap()
      .then(() => {
        handleShowModal('showEncuestaModal', false);
        setEncuestaForm({ titulo: '', descripcion: '' });
        setErrors((prev) => ({ ...prev, encuesta: null }));
      })
      .catch((err) => setErrors((prev) => ({ ...prev, encuesta: err.message || 'Error al crear la encuesta.' })));
  };

  const handleEliminarEncuesta = (id) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar esta encuesta?')) {
      dispatch(eliminarEncuesta(id)).catch((err) => console.error('Error al eliminar encuesta:', err));
    }
  };

  const handleAbrirModalPregunta = (id) => {
    setSelectedEncuestaId(id);
    dispatch(fetchPreguntasByEncuestaId(id));
    handleShowModal('showPreguntaModal', true);
  };

  const handleCrearPregunta = async () => {
    const { pregunta, tipoRespuesta, alternativas } = preguntaForm;
  
    // Validar longitud de la pregunta
    if (pregunta.trim().length <= 10) {
      alert('La pregunta debe tener más de 10 caracteres.');
      return;
    }
  
    // Validar alternativas en preguntas de opción múltiple
    if (tipoRespuesta === 'opcion_multiple') {
      const alternativasValidas = alternativas.filter((alt) => alt.trim().length > 1);
      if (alternativasValidas.length < 2) {
        alert('Debe haber al menos dos alternativas válidas, con más de un carácter.');
        return;
      }
    }
  
    try {
      // Preparar el payload
      const payload = {
        pregunta: pregunta.trim(),
        tipo_respuesta: tipoRespuesta,
        alternativas: tipoRespuesta === 'opcion_multiple' 
          ? alternativas.filter((alt) => alt.trim().length > 1) 
          : null,
      };
  
      // Verificar si es edición o creación
      if (editMode.isEditing) {
        await api.put(`/api/encuestas/preguntas/${editMode.preguntaId}`, payload);
        alert('Pregunta actualizada exitosamente.');
      } else {
        await api.post(`/api/encuestas/${selectedEncuestaId}/preguntas`, payload);
        alert('Pregunta añadida exitosamente.');
      }
  
      // Resetear formulario y recargar preguntas
      dispatch(fetchPreguntasByEncuestaId(selectedEncuestaId));
      setPreguntaForm({ pregunta: '', tipoRespuesta: 'opcion_multiple', alternativas: [''] });
      setEditMode({ isEditing: false, preguntaId: null });
      setErrors({}); // Limpiar errores
    } catch (error) {
      console.error('Error al gestionar la pregunta:', error);
      setErrors((prev) => ({
        ...prev,
        pregunta: error.response?.data?.message || 'Error al gestionar la pregunta.',
      }));
    }
  };
  

  const handleEliminarPregunta = async (id) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar esta pregunta?')) {
      try {
        await api.delete(`/api/encuestas/preguntas/${id}`);
        dispatch(fetchPreguntasByEncuestaId(selectedEncuestaId));
        alert('Pregunta eliminada exitosamente.');
      } catch (error) {
        console.error('Error al eliminar pregunta:', error);
        alert(error.response?.data?.message || 'Error al eliminar la pregunta.');
      }
    }
  };

  const handleEditarPregunta = (id, preguntaText, tipo, alternativasArray) => {
    setEditMode({ isEditing: true, preguntaId: id });
    setPreguntaForm({ pregunta: preguntaText, tipoRespuesta: tipo, alternativas: alternativasArray || [''] });
  };

  const handleAddAlternativa = () => setPreguntaForm((prev) => ({ ...prev, alternativas: [...prev.alternativas, ''] }));

  const handleRemoveAlternativa = (index) => {
    setPreguntaForm((prev) => ({
      ...prev,
      alternativas: prev.alternativas.filter((_, i) => i !== index),
    }));
  };

  const handleAlternativaChange = (value, index) => {
    setPreguntaForm((prev) => {
      const nuevasAlternativas = [...prev.alternativas];
      nuevasAlternativas[index] = value;
      return { ...prev, alternativas: nuevasAlternativas };
    });
  };

  return (
    <div className="container mt-4">
      <h1 className="text-center">Gestión de Encuestas</h1>

      {error && <Alert variant="danger">{error.message || 'Ocurrió un error inesperado'}</Alert>}
      {errors.encuesta && <Alert variant="danger">{errors.encuesta}</Alert>}
      {errors.pregunta && <Alert variant="danger">{errors.pregunta}</Alert>}

      <div className="d-flex justify-content-end mb-3">
        <Button variant="primary" onClick={() => handleShowModal('showEncuestaModal', true)}>
          Crear Encuesta
        </Button>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Título</th>
            <th>Descripción</th>
            <th>Activo</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {encuestas.length > 0 ? (
            encuestas.map((encuesta, index) => (
              <tr key={encuesta.id}>
                <td>{index + 1}</td>
                <td>{encuesta.titulo}</td>
                <td>{encuesta.descripcion}</td>
                <td>{encuesta.activo ? 'Sí' : 'No'}</td>
                <td>
                  <Button
                    variant="primary"
                    size="sm"
                    className="me-2"
                    onClick={() => handleAbrirModalPregunta(encuesta.id)}
                  >
                    Gestionar Preguntas
                  </Button>
                  <Button variant="danger" size="sm" onClick={() => handleEliminarEncuesta(encuesta.id)}>
                    Eliminar
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">
                No hay encuestas disponibles
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <Modal show={modalState.showEncuestaModal} onHide={() => handleShowModal('showEncuestaModal', false)}>
        <Modal.Header closeButton>
          <Modal.Title>Crear Nueva Encuesta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleCrearEncuesta}> {/* El formulario usa onSubmit aquí */}
            <Form.Group className="mb-3">
              <Form.Label>Título</Form.Label>
              <Form.Control
                type="text"
                name="titulo"
                placeholder="Ingrese el título de la encuesta"
                value={encuestaForm.titulo}
                onChange={handleChange(setEncuestaForm)}
              />
            </Form.Group>
            {errors.encuesta && <Alert variant="danger">{errors.encuesta}</Alert>} {/* Mostrar errores de validación */}

            <Form.Group className="mb-3">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="descripcion"
                placeholder="Ingrese una descripción opcional"
                value={encuestaForm.descripcion}
                onChange={handleChange(setEncuestaForm)}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Crear Encuesta
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleShowModal('showEncuestaModal', false)}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalState.showPreguntaModal} onHide={() => handleShowModal('showPreguntaModal', false)}>
        <Modal.Header closeButton>
          <Modal.Title>Gestionar Preguntas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Pregunta</Form.Label>
              <Form.Control
                type="text"
                name="pregunta"
                placeholder="Ingrese la pregunta (mínimo 10 caracteres)"
                value={preguntaForm.pregunta}
                isInvalid={preguntaForm.pregunta.length > 0 && preguntaForm.pregunta.length <= 10}
                onChange={handleChange(setPreguntaForm)}
              />
              <Form.Control.Feedback type="invalid">
                La pregunta debe tener más de 10 caracteres.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Tipo de Respuesta</Form.Label>
              <Form.Select
                name="tipoRespuesta"
                value={preguntaForm.tipoRespuesta}
                onChange={handleChange(setPreguntaForm)}
              >
                <option value="opcion_multiple">Opción Múltiple</option>
                <option value="abierta">Abierta</option>
              </Form.Select>
            </Form.Group>
            {preguntaForm.tipoRespuesta === 'opcion_multiple' && (
              <Form.Group className="mb-3">
              <Form.Label>Alternativas</Form.Label>
              {preguntaForm.alternativas.map((alt, index) => (
                <div key={index} className="d-flex align-items-center mb-2">
                  <Form.Control
                    type="text"
                    placeholder={`Alternativa ${index + 1}`}
                    value={alt}
                    isInvalid={alt.trim().length > 0 && alt.trim().length <= 1}
                    onChange={(e) => handleAlternativaChange(e.target.value, index)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Cada alternativa debe tener más de un carácter.
                  </Form.Control.Feedback>
                  <Button variant="danger" size="sm" className="ms-2" onClick={() => handleRemoveAlternativa(index)}>
                    Eliminar
                  </Button>
                </div>
              ))}
              <Button variant="success" size="sm" onClick={handleAddAlternativa}>
                Añadir Alternativa
              </Button>
            </Form.Group>
            )}
          </Form>
          <Button variant="primary" className="me-2" onClick={handleCrearPregunta}>
            {editMode.isEditing ? 'Actualizar' : 'Añadir'}
          </Button>
          {editMode.isEditing && (
            <Button variant="secondary" onClick={() => setEditMode({ isEditing: false, preguntaId: null })}>
              Cancelar
            </Button>
          )}
          <hr />
          <h5>Preguntas:</h5>
          <ul>
            {(preguntas[selectedEncuestaId] || []).map((preg) => (
              <li key={preg.id}>
                {preg.pregunta} - ({preg.tipo_respuesta})
                {preg.tipo_respuesta === 'opcion_multiple' && preg.alternativas ? (
                  <ul>
                    {preg.alternativas.map((alt, index) => (
                      <li key={index}>{alt}</li>
                    ))}
                  </ul>
                ) : null}
                <Button
                  variant="warning"
                  size="sm"
                  className="ms-2"
                  onClick={() =>
                    handleEditarPregunta(preg.id, preg.pregunta, preg.tipo_respuesta, preg.alternativas)
                  }
                >
                  Editar
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  className="ms-2"
                  onClick={() => handleEliminarPregunta(preg.id)}
                >
                  Eliminar
                </Button>
              </li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleShowModal('showPreguntaModal', false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EncuestasPage;
