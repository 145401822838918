import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import { Container, Spinner, Alert } from 'react-bootstrap';
import { fetchBeneficios } from '../redux/beneficiosSlice';

const BeneficiosPage = () => {
  const dispatch = useDispatch();
  const { beneficios, loading, error } = useSelector((state) => state.beneficios);

  useEffect(() => {
    // Despachar la acción para obtener beneficios al cargar el componente
    dispatch(fetchBeneficios());
  }, [dispatch]);

  return (
    <Container className="mt-4">
      <h1 className="mb-4 text-center">Nuestros Beneficios</h1>
      {loading && <Spinner animation="border" variant="primary" />}
      {error && <Alert variant="danger">{error}</Alert>}
      {!loading && !error && beneficios.length === 0 && (
        <Alert variant="info">No hay beneficios disponibles en este momento.</Alert>
      )}
      <Accordion defaultActiveKey="0">
        {beneficios.map((beneficio, index) => (
          <Accordion.Item eventKey={index.toString()} key={beneficio.id}>
            <Accordion.Header>{beneficio.titulo}</Accordion.Header>
            <Accordion.Body>{beneficio.descripcion}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </Container>
  );
};

export default BeneficiosPage;
