import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const PrivacyPolicyPage = () => {
    return (
        <Container className="my-5">
            <Row className="justify-content-center">
                <Col md={10}>
                    <Card className="shadow-lg">
                        <Card.Body>
                            <h1 className="text-center text-primary mb-4">Política de Privacidad</h1>
                            <p>
                                Bienvenido a nuestra aplicación moderna desarrollada con tecnologías
                                avanzadas como React, Redux, Node.js y Express. Nos comprometemos a
                                proteger la privacidad y seguridad de sus datos personales conforme
                                a las normativas vigentes, incluidas las nuevas disposiciones de la
                                ley marco de Ciber Seguridad que entrarán en vigencia en marzo de 2025.
                            </p>

                            <h3 className="mt-4">Recopilación y Uso de Datos</h3>
                            <p>
                                Recopilamos y utilizamos sus datos únicamente para los propósitos establecidos en esta política, como facilitar la comunicación entre los miembros del sindicato, realizar encuestas, mantenerlos informados sobre beneficios disponibles y garantizar el correcto funcionamiento de la aplicación. Nos comprometemos a utilizar sus datos de manera responsable y en cumplimiento con las normativas vigentes de protección de datos.
                            </p>

                            <h3 className="mt-4">Medidas de Seguridad</h3>
                            <p>
                                Nos tomamos muy en serio la protección de sus datos personales. Para garantizar su seguridad, aplicamos técnicas avanzadas que evitan el acceso no autorizado. Estas medidas están diseñadas para proteger su información en todo momento y cumplir con las normativas vigentes de privacidad y seguridad de datos
                            </p>


                            <h3 className="mt-4">Cabeceras de Seguridad</h3>
                            <p>
                                Implementamos configuraciones avanzadas de seguridad en las cabeceras HTTP para
                                proteger contra ataques modernos:
                            </p>
                            <ul>
                                <li>Content-Security-Policy</li>
                                <li>Permissions-Policy</li>
                                <li>Referrer-Policy</li>
                                <li>Strict-Transport-Security</li>
                                <li>X-Content-Type-Options</li>
                                <li>X-Frame-Options</li>
                            </ul>

                            <h3 className="mt-4">Otros Mecanismos de Protección</h3>
                            <p>
                                Adicionalmente, contamos con:
                            </p>
                            <ul>
                                <li>
                                    <strong>Forzado de HTTPS:</strong> Todas las comunicaciones se realizan a través
                                    de conexiones seguras.
                                </li>
                                <li>
                                    <strong>Prevención de inyecciones SQL:</strong> Protegemos nuestras bases de
                                    datos mediante Sequelize y consultas parametrizadas.
                                </li>
                                <li>
                                    <strong>Autenticación robusta:</strong> Usamos Passport y bcrypt para garantizar
                                    la seguridad de las credenciales de acceso.
                                </li>
                                <li>
                                    <strong>Rate limits:</strong> Limitamos los intentos de login para prevenir ataques
                                    de fuerza bruta.
                                </li>
                            </ul>

                            <h3 className="mt-4">Sus Derechos</h3>
                            <p>
                                Usted tiene derecho a acceder, modificar y eliminar sus datos en cualquier momento.
                                Si tiene preguntas o inquietudes sobre nuestra política de privacidad, no dude en
                                ponerse en contacto con nosotros.
                            </p>

                            <p className="text-center mt-4">
                                <strong>Última actualización:</strong> Enero de 2025
                            </p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default PrivacyPolicyPage;
