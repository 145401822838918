import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/axiosConfig';

// Thunks

// Obtener convenios públicos
export const fetchConvenios = createAsyncThunk(
  'convenios/fetchConvenios',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/api/convenios/listar');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error al obtener convenios');
    }
  }
);

// Crear convenio (privado)
export const createConvenio = createAsyncThunk(
  'convenios/createConvenio',
  async (convenio, { rejectWithValue }) => {
    try {
      const response = await api.post('/api/convenios/crear', convenio);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error al crear convenio');
    }
  }
);

// Editar convenio (privado)
export const editConvenio = createAsyncThunk(
  'convenios/editConvenio',
  async ({ id, convenio }, { rejectWithValue }) => {
    try {
      const response = await api.put(`/api/convenios/editar/${id}`, convenio);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error al editar convenio');
    }
  }
);

// Desactivar convenio (privado)
export const deactivateConvenio = createAsyncThunk(
  'convenios/deactivateConvenio',
  async (id, { rejectWithValue }) => {
    try {
      await api.delete(`/api/convenios/desactivar/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error al desactivar convenio');
    }
  }
);

const conveniosSlice = createSlice({
  name: 'convenios',
  initialState: { convenios: [], loading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch convenios
      .addCase(fetchConvenios.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchConvenios.fulfilled, (state, action) => {
        state.loading = false;
        state.convenios = action.payload;
      })
      .addCase(fetchConvenios.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Create convenio
      .addCase(createConvenio.fulfilled, (state, action) => {
        state.convenios.push(action.payload);
      })

      // Edit convenio
      .addCase(editConvenio.fulfilled, (state, action) => {
        const index = state.convenios.findIndex((c) => c.id === action.payload.id);
        if (index !== -1) {
          state.convenios[index] = action.payload;
        }
      })

      // Deactivate convenio
      .addCase(deactivateConvenio.fulfilled, (state, action) => {
        state.convenios = state.convenios.filter((c) => c.id !== action.payload);
      })

      // General error handling
      .addCase(createConvenio.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(editConvenio.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(deactivateConvenio.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export default conveniosSlice.reducer;
