import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Container, Row, Col, Alert, Card } from "react-bootstrap";
import Avatar from "react-avatar";
import { obtenerPerfil, actualizarPerfil } from "../redux/profileSlice";

const ProfilePage = () => {
  const dispatch = useDispatch();
  const { usuario, loading, error, mensaje } = useSelector((state) => state.profile);

  const [formData, setFormData] = useState({
    nombre: "",
    primer_apellido: "",
    segundo_apellido: "",
    telefono: "",
    direccion: "",
    email_personal: "",
    contacto_emergencia: "",
    telefono_emergencia: "",
  });

  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    dispatch(obtenerPerfil());
  }, [dispatch]);

  useEffect(() => {
    if (usuario) {
      setFormData({
        nombre: usuario.nombre || "",
        primer_apellido: usuario.primer_apellido || "",
        segundo_apellido: usuario.segundo_apellido || "",
        telefono: usuario.telefono || "",
        direccion: usuario.direccion || "",
        email_personal: usuario.email_personal || "",
        contacto_emergencia: usuario.contacto_emergencia || "",
        telefono_emergencia: usuario.telefono_emergencia || "",
      });
    }
  }, [usuario]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validación en tiempo real
    const errors = { ...formErrors };
    switch (name) {
      case "nombre":
      case "primer_apellido":
      case "segundo_apellido":
        if (value && !/^[a-zA-Z]+$/.test(value)) {
          errors[name] = "Este campo solo puede contener letras.";
        } else {
          delete errors[name];
        }
        break;
      case "email_personal":
        if (value && !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)) {
          errors[name] = "Debe ser un email válido.";
        } else {
          delete errors[name];
        }
        break;
      case "contacto_emergencia":
      case "telefono_emergencia":
        if (value && typeof value !== "string") {
          errors[name] = "Debe ser un texto válido.";
        } else {
          delete errors[name];
        }
        break;

      case "telefono":
        if (value && !/^\+56\d{9}$/.test(value)) {
          errors[name] = "Debe ser un número de celular válido para Chile (+56XXXXXXXXX).";
        } else {
          delete errors[name];
        }
        break;
      case "direccion":
        if (value && typeof value !== "string") {
          errors[name] = "La dirección debe ser un texto válido.";
        } else {
          delete errors[name];
        }
        break;
      default:
        break;
    }
    setFormErrors(errors);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Validar todos los campos
    const errors = {};
    if (formData.nombre && !/^[a-zA-Z]+$/.test(formData.nombre)) {
      errors.nombre = "El nombre solo puede contener letras.";
    }
    if (formData.primer_apellido && !/^[a-zA-Z]+$/.test(formData.primer_apellido)) {
      errors.primer_apellido = "El primer apellido solo puede contener letras.";
    }
    if (formData.segundo_apellido && !/^[a-zA-Z]+$/.test(formData.segundo_apellido)) {
      errors.segundo_apellido = "El segundo apellido solo puede contener letras.";
    }
    if (formData.telefono && !/^\+56\d{9}$/.test(formData.telefono)) {
      errors.telefono = "El teléfono debe ser un número de celular válido para Chile (+56XXXXXXXXX).";
    }
    if (formData.direccion && typeof formData.direccion !== "string") {
      errors.direccion = "La dirección debe ser un texto válido.";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    setFormErrors({});
    dispatch(actualizarPerfil(formData));
  };

  if (loading && !usuario) {
    return <Container><p>Cargando perfil...</p></Container>;
  }

  return (
    <Container className="mt-4">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card>
            <Card.Body>
              <Row className="mb-4">
                <Col className="text-center">
                  <Avatar
                    name={`${formData.nombre} ${formData.primer_apellido}`}
                    size="100"
                    round={true}
                  />
                  <h3>{`${formData.nombre} ${formData.primer_apellido} ${formData.segundo_apellido}`}</h3>
                  <p>{formData.direccion}</p>
                </Col>
              </Row>
              {error && <Alert variant="danger">{error}</Alert>}
              {mensaje && <Alert variant="success">{mensaje}</Alert>}
              <Form onSubmit={handleFormSubmit}>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formNombre" className="mb-3">
                      <Form.Label>Nombre</Form.Label>
                      <Form.Control
                        type="text"
                        name="nombre"
                        value={formData.nombre}
                        onChange={handleInputChange}
                        placeholder="Ingresa tu nombre"
                        isInvalid={!!formErrors.nombre}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors.nombre}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formPrimerApellido" className="mb-3">
                      <Form.Label>Primer Apellido</Form.Label>
                      <Form.Control
                        type="text"
                        name="primer_apellido"
                        value={formData.primer_apellido}
                        onChange={handleInputChange}
                        placeholder="Ingresa tu primer apellido"
                        isInvalid={!!formErrors.primer_apellido}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors.primer_apellido}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formSegundoApellido" className="mb-3">
                      <Form.Label>Segundo Apellido</Form.Label>
                      <Form.Control
                        type="text"
                        name="segundo_apellido"
                        value={formData.segundo_apellido}
                        onChange={handleInputChange}
                        placeholder="Ingresa tu segundo apellido"
                        isInvalid={!!formErrors.segundo_apellido}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors.segundo_apellido}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formTelefono" className="mb-3">
                      <Form.Label>Teléfono</Form.Label>
                      <Form.Control
                        type="text"
                        name="telefono"
                        value={formData.telefono}
                        onChange={handleInputChange}
                        placeholder="Ingresa tu teléfono"
                        isInvalid={!!formErrors.telefono}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors.telefono}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="formDireccion" className="mb-3">
                  <Form.Label>Dirección</Form.Label>
                  <Form.Control
                    type="text"
                    name="direccion"
                    value={formData.direccion}
                    onChange={handleInputChange}
                    placeholder="Ingresa tu dirección"
                    isInvalid={!!formErrors.direccion}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.direccion}
                  </Form.Control.Feedback>
                </Form.Group>
                {/* Nuevos Campos del Formulario */}

                <Form.Group controlId="formEmailPersonal" className="mb-3">
                  <Form.Label>Email Personal</Form.Label>
                  <Form.Control
                    type="email"
                    name="email_personal"
                    value={formData.email_personal}
                    onChange={handleInputChange}
                    placeholder="Ingresa tu correo personal"
                    isInvalid={!!formErrors.email_personal}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.email_personal}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formContactoEmergencia" className="mb-3">
                  <Form.Label>Contacto de Emergencia</Form.Label>
                  <Form.Control
                    type="text"
                    name="contacto_emergencia"
                    value={formData.contacto_emergencia}
                    onChange={handleInputChange}
                    placeholder="Ingresa el contacto de emergencia"
                    isInvalid={!!formErrors.contacto_emergencia}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.contacto_emergencia}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formTelefonoEmergencia" className="mb-3">
                  <Form.Label>Teléfono de Emergencia</Form.Label>
                  <Form.Control
                    type="text"
                    name="telefono_emergencia"
                    value={formData.telefono_emergencia}
                    onChange={handleInputChange}
                    placeholder="Ingresa el teléfono de emergencia"
                    isInvalid={!!formErrors.telefono_emergencia}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.telefono_emergencia}
                  </Form.Control.Feedback>
                </Form.Group>


                <Button variant="primary" type="submit" disabled={loading}>
                  {loading ? "Guardando..." : "Actualizar Perfil"}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ProfilePage;
